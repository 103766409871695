@import '~modern-normalize/modern-normalize.css';
@import 'config';

@import 'typography/mechanics';
@import 'typography/base';
@import 'typography/scale';

@import 'mixins';

@import 'globals/base.scss';
@import 'globals/button.scss';
@import 'globals/fitvids.scss';
@import 'globals/fonts.scss';
@import 'globals/footer.scss';
@import 'globals/form.scss';
@import 'globals/grid.scss';
@import 'globals/icon.scss';
@import 'globals/input.scss';
@import 'globals/lazyloading.scss';
@import 'globals/markup.scss';
@import 'globals/section.scss';
@import 'globals/skins.scss';
@import 'globals/type.scss';

body {
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.layout {
    margin: rhythm(6) 0;

    @media (max-width: 800px) {
        margin: rhythm(2) 0;
    }

    &[class*='bg--'] {
        padding: rhythm(6) 0;
        margin: 0;

        @media (max-width: 800px) {
            padding: rhythm(2) 0;
        }
    }
}

.cta {
    position: relative;
    text-align: center;
    width: 95%;
    margin: 0 auto;
    padding: rhythm(8) rhythm(0);
}

.brick-texture {
    position: absolute;
    inset: 0;
}

.cta .standfirst {
    padding: 0 rhythm(30);
    position: relative;

    @media (max-width: 1160px) {
        padding: 0 rhythm(18);
    }

    @media (max-width: 960px) {
        padding: 0 rhythm(8);
    }

    @media (max-width: 700px) {
        padding: 0 rhythm(2);
    }
}
