// Colours
$black: #394345;
$white: #fffcfc;
$grey: #e3e3e3;

$blue: #4299e1;
$green: #48bb78;
$red: #fa4545;

$primary: $red;
$bg: $grey;

$text-shadow: 1px 1px 5px transparentize($black, 0.6);

// Layering
$z-under: -1;
$z-level-0: 0; // Page
$z-level-1: 100; // Page
$z-level-2: 200; // Page
$z-level-3: 300; // Page
$z-level-4: 400; // Alerts
$z-level-5: 500; // Navigation
$z-level-6: 600; // Header
$z-level-7: 700; // Takeover
$z-level-8: 800; // Flyout
$z-level-9: 900; // Toast
$z-level-10: 9999; // System

// Type setting
// set the body font
$type-body-font: 'adobe-garamond-pro', sans-serif;
$type-title-font: 'gill-sans-nova', sans-serif;

$body: $type-body-font;
$title: $type-title-font;
$bold: $title;

// Transitions
$anim-time: 0.1s;
$anim-curve: cubic-bezier(0.4, 0, 0.2, 1);

// Grid
$grid-width: 1400px;

// rhythm
// set the line-height
// make sure that this is target leading in px / base point size
$base-font-size: 18;
$target-line-height: 32;
