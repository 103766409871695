// Button

$button-border-width: 2px;
$button-border-radius: 5px;

// Base

button {
    cursor: pointer;
    @include base;
}

.button {
    color: $primary;
    text-transform: uppercase;
    font-family: $title;
    background-color: transparent;
    display: inline-block;
    position: relative;
    border: $button-border-width solid $primary;
    border-radius: $button-border-radius;
    font-weight: bold;
    text-align: center;
    cursor: pointer;
    padding: rhythm(1) rhythm(4) rhythm(1.5);
    outline: none;
    letter-spacing: 4px;
    // transition:
    //     color $anim-time $anim-curve,
    //     background-color $anim-time $anim-curve;

    &:hover,
    &:focus {
        color: $white;
        background-color: $primary;
    }

    &:active {
        transform: translateY(2px);
    }

    &[disabled] {
        color: inherit;
        background-color: inherit;
        border-color: inherit;
        cursor: default;
        transform: none;
        opacity: 0.1;
    }
}

// Variants

.button--alt {
    color: $white;
    background-color: $primary;

    &:hover,
    &:focus {
        color: $primary;
        background-color: transparent;
    }
}
