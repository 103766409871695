.layout {
    margin: rhythm(6) 0;

    @media (max-width: 800px) {
        margin: rhythm(2) 0;
    }

    &[class*='bg--'] {
        padding: rhythm(6) 0;
        margin: 0;

        @media (max-width: 800px) {
            padding: rhythm(2) 0;
        }
    }
}
