// Markup

.markup {
    margin-bottom: rhythm(4);
    @include gamma;

    &:last-child {
        margin-bottom: 0;
    }

    a {
        @include link($primary, $underline: true);
    }

    strong {
        font-weight: bold;
    }

    img {
        display: block;
        height: auto; // Required to combat height attributes
        margin: rhythm(4) auto;
    }

    ul,
    ol {
        text-align: left;
        padding-left: 1em;
        margin: 0 0 rhythm(4);

        &:last-child {
            margin-bottom: 0;
        }
    }

    ol {
        list-style-type: decimal;
    }

    ul {
        list-style-type: disc;
    }

    table {
        width: 100%;
        text-align: left;
        margin-bottom: rhythm(4);

        &:last-child {
            margin-bottom: 0;
        }
    }

    tr:nth-child(odd) {
        background-color: $white;
    }

    th,
    td {
        padding: rhythm(1);
    }

    blockquote {
        font-family: $title;
        font-weight: bold;
        padding: 0 10%;
        margin: rhythm(8) 0;
    }

    .alignright {
        float: right;
        margin: 0 0 rhythm(2) rhythm(4);

        @media (max-width: 800px) {
            float: none;
            margin: rhythm(4) auto;
        }
    }

    .alignleft {
        float: left;
        margin: 0 rhythm(4) rhythm(2) 0;

        @media (max-width: 800px) {
            float: none;
            margin: rhythm(4) auto;
        }
    }

    .alignnone {
        margin-right: 0;
        margin-left: 0;
    }

    .wp-caption {
        width: auto !important; // Required to override inline styles
        margin: rhythm(8) 0;

        > p:empty {
            display: none; // for some reason wp caption is adding a blank p tag
        }
    }
}
