.fitVids-wrapper {
    position: relative;
    iframe {
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        height: 100%;
    }
}
