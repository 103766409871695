// Footer

.footer {
    color: $red;
    background-color: $white;
    margin-top: 145px;
    @extend .title-font;
    text-transform: uppercase;
    border-bottom: 10px solid $red;

    a {
        @include link($red, $black);
    }

    // .button {
    //     @extend .button--alt;
    // }

    .input--checkbox label:before {
        border-color: $red;
    }

    address {
        font-style: normal;
    }
}

.footer-title {
    text-transform: uppercase;
    @include base;
}

.footer-menu {
    position: relative;
    z-index: $z-level-1;
}

@media (max-width: 400px) {
    .encoded-email {
        word-wrap: break-word;
    }
}
