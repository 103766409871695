// Input

input,
select,
textarea {
    font-family: $body;
    outline: none;
}

select {
    cursor: pointer;
}

.input {
    background-color: $white;
    padding: rhythm(1);
    border-bottom: 2px solid $red;
    border-radius: 3px;

    &:focus-within {
        background-color: $white;
        border-color: $black;
    }
}

.input-field {
    color: $black;
    background-color: transparent;
    width: 100%;
    border: none;
    outline: none;
    @include base;
    @include placeholder($red);
}

label {
    display: block;
    text-align: left;

    a {
        text-decoration: underline;
    }
}

// Variants

.input--checkbox {
    background-color: transparent !important;
    position: relative;
    border: none;
    min-height: rhythm(4);
    padding: 0 0 0 rhythm(6) !important;

    input[type='checkbox'] {
        position: absolute;
        top: rhythm(2);
        left: rhythm(1);
        width: rhythm(3);
        opacity: 0;
        margin: 0;

        &:checked + label:after {
            content: '';
        }
    }

    input:focus ~ label:before {
        border: 3px solid;
    }

    label {
        cursor: pointer;
        @include iota;

        &:before {
            content: '';
            border: 2px solid $black;
            border-radius: 3px;
            position: absolute;
            top: 0;
            left: 0;
            box-sizing: border-box;
            @include size(#{rhythm(4)});
        }

        &:after {
            position: absolute;
            top: rhythm(1.1);
            left: rhythm(1);
            width: rhythm(2);
            height: rhythm(1.2);
            border-left: 4px solid $primary;
            border-bottom: 4px solid $primary;
            transform: rotate(-45deg);
        }
    }
}

.input--select {
    position: relative;

    input,
    select {
        display: block;
        position: relative;
        z-index: 1;
        padding: 0 rhythm(4) 0 0;
        appearance: none;
    }

    &:after {
        content: '';
        position: absolute;
        top: 0;
        right: rhythm(2);
        bottom: 0;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: rhythm(0.5) rhythm(0.5) 0 rhythm(0.5);
        border-color: $black transparent transparent transparent;
        margin: auto;
    }
}
