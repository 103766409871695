.form {
	margin-bottom: rhythm(4);

	&:last-child {
		margin-bottom: 0;
	}
}

.form-row {
	display: flex;
    flex-direction: column;
	margin-bottom: rhythm(2);

	.input {
		flex: 1;
		margin-right: rhythm(2);

		&:last-child {
			margin-right: 0;
		}
	}

	&:last-child {
		margin-bottom: 0;
	}
}
