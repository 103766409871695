h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: $title;
    font-weight: 600;
    letter-spacing: 4px;
}

.omega {
    @include omega;
}

h1,
.alpha {
    @include alpha;
}

h2,
.beta,
.standfirst {
    @include beta;
}

h3,
.gamma {
    @include gamma;
}

h4,
.delta {
    @include delta;
}

h5,
.epsilon {
    @include epsilon;
}

h6 {
    @include base;
    text-transform: uppercase;
    text-decoration: underline;
}

.zeta {
    @include zeta;
}

.iota {
    @include iota;
}

.base {
    @include base;
}

.bold {
    font-family: $bold;
}

.link {
    color: inherit;
}

.subtitle {
    text-transform: uppercase;
    display: inline-block;
    border-bottom: 1px solid;
}

.underline {
    display: inline-block;
    padding-bottom: 0.25rem;
    border-bottom: 1px solid;
}

.title-font {
    font-family: $title;
    font-weight: 600;
    letter-spacing: 4px;
}

.body-font {
    font-family: $body;
    font-weight: 400;
}

.standfirst {
    text-transform: uppercase;
    font-family: $title;
    font-weight: 600;
    letter-spacing: 4px;
}

.page-title {
    text-transform: uppercase;
    font-family: $title;
    font-weight: 600;
    letter-spacing: 4px;
    color: $red;
    @include zeta;
}

.half-margin {
    margin-bottom: rhythm(2);
}
