@mixin omega {
    @include type(61, 11);

    @media (max-width: 800px) {
        @include type(39, 8);
    }
}

@mixin alpha {
    @include type(39, 7);

    @media (max-width: 800px) {
        @include type(31, 6);
    }
}

@mixin beta {
    @include type(31, 6);

    @media (max-width: 800px) {
        @include type(25, 5);
    }
}

@mixin gamma {
    @include type(25, 5);

    @media (max-width: 800px) {
        @include type(20, 5);
    }
}

@mixin delta {
    @include gamma;
}

@mixin epsilon {
    @include type(20);

    @media (max-width: 800px) {
        @include type(16);
    }
}

@mixin zeta {
    @include type(16);
}

@mixin iota {
    @include type(14, 3);
}

@mixin base {
    @include type;
}

/*  
 * Example :
 *   @mixin alpha {
 *       @include type(32, 6);
 *
 *       @media (max-width: 800px) {
 *           @include type(24);
 *       }
 *   }
 */
